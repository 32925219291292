import {
  AnchorRouter,
  FaceBookIcon,
  FynbosLogo,
  InstagramIcon,
  LinkedInIcon,
  Router,
  XIcon
} from '@/Components'
import { MarketingHeader } from '@/Layouts/MarketingHeader'
import { FC, ReactNode } from 'react'

type MarketingScaffoldProps = {
  children?: ReactNode
}

export const MarketingLayout: FC<MarketingScaffoldProps> = ({ children }) => {
  return (
    <div className='relative inset-0 flex min-h-dvh w-full flex-col'>
      <MarketingHeader />
      {children}
      <footer className='w-full bg-black'>
        <div className='mx-auto flex w-full max-w-5xl flex-col items-start gap-10 p-10 py-20 text-white'>
          <FynbosLogo />
          <div className='grid w-full grid-cols-3 gap-10'>
            <div className='col-span-full flex flex-col gap-2 text-xl sm:col-span-1'>
              <h3 className='mb-4 font-medium'>Company</h3>
              <AnchorRouter
                href='#pricing'
                className='font-normal text-white underline-offset-2 hover:underline'
              >
                Pricing
              </AnchorRouter>
              <Router
                href={route('learn')}
                className='font-normal text-white underline-offset-2 hover:underline'
              >
                Learn
              </Router>
              <Router
                href={route('login')}
                className='font-normal text-white underline-offset-2 hover:underline'
              >
                Start
              </Router>
            </div>
            <div className='col-span-full flex flex-col gap-2 text-xl sm:col-span-1'>
              <h3 className='mb-4 font-medium'>Legal</h3>
              <Router
                href={route('marketing.terms-and-conditions')}
                className='font-normal text-white underline-offset-2 hover:underline'
              >
                Terms & Conditions
              </Router>
              <Router
                href={route('marketing.privacy-policy')}
                className='font-normal text-white underline-offset-2 hover:underline'
              >
                Privacy Policy
              </Router>
              <Router
                href={route('marketing.fais-disclosures')}
                className='font-normal text-white underline-offset-2 hover:underline'
              >
                FAIS Disclosures
              </Router>
              <AnchorRouter
                target='_blank'
                href='https://cdn.fynbos.app/pdfs/fynbos-PAIA-manual-October-2024.pdf'
                className='font-normal text-white underline-offset-2 hover:underline'
              >
                PAIA Manual
              </AnchorRouter>
              <Router
                href={route('marketing.fica-policy')}
                className='font-normal text-white underline-offset-2 hover:underline'
              >
                FICA Policy
              </Router>
              <Router
                href={route('marketing.complaints-policy')}
                className='font-normal text-white underline-offset-2 hover:underline'
              >
                Complaints Policy
              </Router>
            </div>
            <div className='col-span-full flex flex-col gap-2 text-xl sm:col-span-1'>
              <h3 className='mb-4 font-medium'>Social</h3>
              <div className='flex gap-6'>
                <AnchorRouter
                  target='_blank'
                  href='https://www.linkedin.com/company/fynbos'
                >
                  <LinkedInIcon className='text-white' />
                </AnchorRouter>
                <AnchorRouter
                  target='_blank'
                  href='https://twitter.com/fynbosmoney'
                >
                  <XIcon className='text-white' />
                </AnchorRouter>
                <AnchorRouter
                  target='_blank'
                  href='https://facebook.com/fynbosmoney'
                >
                  <FaceBookIcon className='text-white' />
                </AnchorRouter>
                <AnchorRouter
                  target='_blank'
                  href='https://instagram.com/fynbosmoney'
                >
                  <InstagramIcon className='text-white' />
                </AnchorRouter>
              </div>
            </div>
          </div>

          <span className='text-sm text-disabled'>
            &copy; {new Date().getFullYear()} Fynbos Technologies Ltd.
          </span>
        </div>
      </footer>
    </div>
  )
}
